import React, { useState } from 'react';
import {
  ChevronDown,
  ChevronUp,
  Smartphone,
  Settings,
  Crown,
  Bell,
  Gift,
  Clock,
  Package,
  ShoppingCart,
  CreditCard,
  BookOpen,
  BarChart3,
  Edit,
} from 'lucide-react';

const AccordionItem = ({ title, icon, isOpen, onClick, children }) => {
  return (
    <div className="border rounded-lg mb-4 overflow-hidden">
      <button
        onClick={onClick}
        className="w-full p-4 flex items-center justify-between bg-white hover:bg-gray-50 transition-colors"
      >
        <div className="flex items-center space-x-3">
          <div className="text-emerald-700">{icon}</div>
          <span className="font-medium text-lg">{title}</span>
        </div>
        {isOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
      </button>

      <div className={`transition-all duration-300 ease-in-out ${isOpen ? 'max-h-[1000px] p-4' : 'max-h-0'} overflow-hidden`}>
        {children}
      </div>
    </div>
  );
};

const Features = () => {
  const [openSection, setOpenSection] = useState(null);

  const sections = [
    {
      id: 'client',
      title: "Application de commande pour vos clients",
      icon: <Smartphone className="w-6 h-6" />,
      customContent: (
        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mt-1 mr-3 flex-shrink-0">
                  <BookOpen className="w-5 h-5 text-emerald-700" />
                </div>
                <div>
                  <h5 className="font-medium mb-1">Menu digital interactif</h5>
                  <p className="text-gray-600">Descriptions détaillées et personnalisation des commandes</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mt-1 mr-3 flex-shrink-0">
                  <CreditCard className="w-5 h-5 text-emerald-700" />
                </div>
                <div>
                  <h5 className="font-medium mb-1">Paiement simplifié</h5>
                  <p className="text-gray-600">Paiement dans l'app ou sur place</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mt-1 mr-3 flex-shrink-0">
                  <ShoppingCart className="w-5 h-5 text-emerald-700" />
                </div>
                <div>
                  <h5 className="font-medium mb-1">Expérience optimisée</h5>
                  <p className="text-gray-600">Design inspiré de votre camion</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 rounded-xl p-6">
            <div className="space-y-4">
              <div className="p-4 bg-white rounded-lg border border-gray-100">
                <h5 className="font-medium text-emerald-700 mb-2">Pour vos clients</h5>
                <p className="text-gray-600">Une expérience de commande fluide et personnalisée, du choix au paiement</p>
              </div>
              <div className="p-4 bg-white rounded-lg border border-gray-100">
                <h5 className="font-medium text-emerald-700 mb-2">Avantages clés</h5>
                <p className="text-gray-600">Gain de temps, personnalisation des commandes et paiement sécurisé</p>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'manager',
      title: "Plateforme de gestion pour vous",
      icon: <Settings className="w-6 h-6" />,
      customContent: (
        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mt-1 mr-3 flex-shrink-0">
                  <Bell className="w-5 h-5 text-emerald-700" />
                </div>
                <div>
                  <h5 className="font-medium mb-1">Gestion des commandes en direct</h5>
                  <p className="text-gray-600">Notifications instantanées, suivi en temps réel, historique détaillé</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mt-1 mr-3 flex-shrink-0">
                  <BarChart3 className="w-5 h-5 text-emerald-700" />
                </div>
                <div>
                  <h5 className="font-medium mb-1">Analyse des performances</h5>
                  <p className="text-gray-600">Tableaux de bord détaillés, statistiques de vente, comportements clients</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mt-1 mr-3 flex-shrink-0">
                  <Edit className="w-5 h-5 text-emerald-700" />
                </div>
                <div>
                  <h5 className="font-medium mb-1">Gestion autonome</h5>
                  <p className="text-gray-600">Modification du menu, des horaires et des programmes de fidélité en temps réel</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 rounded-xl p-6">
            <div className="space-y-4">
              <div className="p-4 bg-white rounded-lg border border-gray-100">
                <h5 className="font-medium text-emerald-700 mb-2">Pour vous</h5>
                <p className="text-gray-600">Un contrôle total sur votre activité depuis une interface unique</p>
              </div>
              <div className="p-4 bg-white rounded-lg border border-gray-100">
                <h5 className="font-medium text-emerald-700 mb-2">Avantages clés</h5>
                <p className="text-gray-600">Gain de temps, meilleure organisation et prise de décision facilitée</p>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'fidelity',
      title: "Système de fidélité premium personnalisable",
      icon: <Crown className="w-6 h-6" />,
      customContent: (
        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mt-1 mr-3 flex-shrink-0">
                  <Settings className="w-5 h-5 text-emerald-700" />
                </div>
                <div>
                  <h5 className="font-medium mb-1">Double système de fidélité</h5>
                  <p className="text-gray-600">Carte standard gratuite et carte premium payante avec avantages configurables</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mt-1 mr-3 flex-shrink-0">
                  <Gift className="w-5 h-5 text-emerald-700" />
                </div>
                <div>
                  <h5 className="font-medium mb-1">Récompenses personnalisées</h5>
                  <p className="text-gray-600">Définissez vos propres règles de fidélisation et avantages premium</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 rounded-xl p-6">
            <div className="space-y-4">
              <div className="p-4 bg-white rounded-lg border border-gray-100">
                <h5 className="font-medium text-emerald-700 mb-2">Revenus additionnels</h5>
                <p className="text-gray-600">Nouveau flux de revenus via les abonnements premium</p>
              </div>
              <div className="p-4 bg-white rounded-lg border border-gray-100">
                <h5 className="font-medium text-emerald-700 mb-2">Fidélisation renforcée</h5>
                <p className="text-gray-600">Incitez vos clients à revenir plus régulièrement</p>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'marketing',
      title: "Marketing direct",
      icon: <Bell className="w-6 h-6" />,
      customContent: (
        <div className="grid md:grid-cols-2 gap-8">
          <div className="space-y-6">
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mt-1 mr-3 flex-shrink-0">
                  <Package className="w-5 h-5 text-emerald-700" />
                </div>
                <div>
                  <h5 className="font-medium mb-1">Gestion des stocks optimisée</h5>
                  <p className="text-gray-600">Créez des promotions flash pour écouler vos stocks</p>
                </div>
              </div>
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mt-1 mr-3 flex-shrink-0">
                  <Clock className="w-5 h-5 text-emerald-700" />
                </div>
                <div>
                  <h5 className="font-medium mb-1">Boost des heures creuses</h5>
                  <p className="text-gray-600">Envoyez des offres spéciales pour dynamiser les périodes calmes</p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 rounded-xl p-6">
            <div className="space-y-4">
              <div className="p-4 bg-white rounded-lg border border-gray-100">
                <h5 className="font-medium text-emerald-700 mb-2">Promotions flash</h5>
                <p className="text-gray-600">"🍕 -30% sur toutes les pizzas aujourd'hui jusqu'à 19h !"</p>
              </div>
              <div className="p-4 bg-white rounded-lg border border-gray-100">
                <h5 className="font-medium text-emerald-700 mb-2">Gestion des stocks</h5>
                <p className="text-gray-600">"🥖 Derniers croissants de la journée à -50% !"</p>
              </div>
            </div>
          </div>
        </div>
      )
    }
  ];

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-6">
        <h2 className="text-4xl font-bold text-center mb-4">
          Pourquoi choisir Garnii ?
        </h2>
        <p className="text-center text-gray-600 mb-16 max-w-2xl mx-auto">
          Une solution complète pour digitaliser votre activité et fidéliser vos clients
        </p>

        <div className="max-w-3xl mx-auto">
          {sections.map((section) => (
            <AccordionItem
              key={section.id}
              title={section.title}
              icon={section.icon}
              isOpen={openSection === section.id}
              onClick={() => setOpenSection(openSection === section.id ? null : section.id)}
            >
              {section.customContent}
            </AccordionItem>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;